// Modules
import React from "react";

// Components
import {
  VideoPlaylistCard,
  VideoPlaylistCardSortable,
  VideoPlaylistDraggable,
} from "../../";

export const VideoPlaylist = ({
  className,
  activePlaylist,
  setActivePlaylist,
  onDeletePlaylistFile,
  onUpdatePlaylistFile,
  items,
  enableDragging,
  isEditMode,
}) => {
  // Video Playlist Card Items
  const renderedVideoPlaylistCardItems = items.map((item) => (
    <VideoPlaylistCard
      className="video-playlist__item"
      key={item.id}
      playlistPublicId={item.file?.cloudinaryId}
      playlistId={item.id}
      activePlaylist={activePlaylist}
      onDeletePlaylistFile={onDeletePlaylistFile}
      playlistDuration={item.totalDuration}
      playlistLink={item.file?.url}
      playlistName={item.name}
      playlistType={item.contentType}
      setActivePlaylist={setActivePlaylist}
      thumbnail={item.thumbnail}
      isEditMode={isEditMode}
      enableDragging={enableDragging}
    />
  ));

  // Video Playlist Card Sortable Items
  const renderedVideoPlaylistCardSortableItems = items.map((item) => (
    <VideoPlaylistCardSortable
      className="video-playlist__item"
      key={item.id}
      playlistPublicId={item.file?.cloudinaryId}
      playlistId={item.id}
      activePlaylist={activePlaylist}
      onDeletePlaylistFile={onDeletePlaylistFile}
      playlistDuration={item.totalDuration}
      playlistLink={item.file?.url}
      playlistName={item.name}
      playlistType={item.contentType}
      setActivePlaylist={setActivePlaylist}
      thumbnail={item.thumbnail}
      isEditMode={isEditMode}
      enableDragging={enableDragging}
    />
  ));

  return (
    <div className={`video-playlist ${className}`}>
      {!enableDragging && renderedVideoPlaylistCardItems}
      {enableDragging && (
        <VideoPlaylistDraggable
          className="video-playlist__draggable"
          items={items}
          onUpdatePlaylistFile={onUpdatePlaylistFile}
        >
          {renderedVideoPlaylistCardSortableItems}
        </VideoPlaylistDraggable>
      )}
    </div>
  );
};

VideoPlaylist.defaultProps = {
  className: "",
  items: [],
};
