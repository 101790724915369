// Modules
import React from "react";

// Hooks
import { useEffect, useState } from "react";
import { useChapterFile, useChapter } from "../../../../hooks";

// Components
import { Fragment } from "react";
import { VideoAnalytics } from "../../../";

// Style
import "./video-player.styles.scss";

// Static Data
const cloudinary = window.cloudinary;
let vPlayer;

export const VideoPlayer = ({ className }) => {
  const [vPlayerInitialize, setVPlayerInitialize] = useState(false);
  const [activeChapterFile, setActiveChapterFile] = useState(null);
  const [activeChapterFileIndex, setActiveChapterFileIndex] = useState(-1);
  const [playlist, setPlaylist] = useState([]);
  const {
    activeChapterFile: activeChapterFileId,
    chapterFiles,
    setActiveChapterFile: setActiveChapterFileId,
    dispatch,
    isChapterFileLoading,
  } = useChapterFile();
  const { activeChapter } = useChapter();

  useEffect(() => {
    // Get Video Player Element
    const vElem = document.querySelector("video#video-player__video");

    // If Their's Video Player Element & Video Player Instance Does Not Exist, Then Initialize Cloudinary Video Player
    if (vElem && !vPlayer) {
      // Initialize Video Player
      vPlayer = cloudinary.videoPlayer(vElem, {
        cloud_name: process.env.REACT_APP_CLOUDINARY_NAME,
        secure: true,
        showLogo: false,
        preload: "auto",
        analytics: true,
        showJumpControls: true,
        playedEventPercents: Array.from(Array(100), (_, i) => i + 1),
        colors: { base: "rgb(0, 0, 0, 0.3)", accent: "#db8226", text: "#fff" },
      });

      // Set Video Player Initialize To True
      setVPlayerInitialize(true);
    }

    return () => {
      // Dispose Video Player
      vPlayer?.dispose();

      // Reset Video Player Value
      vPlayer = null;
    };
  }, []);

  useEffect(() => {
    if (vPlayer) {
      // Update Active File Id Anytime Source Change
      vPlayer.on("sourcechanged", ({ Player }) => {
        // Get Current Playing Playlist Index
        const currentPlayingPlaylistIndex = Player.playlist().currentIndex();

        // Get Current Playing Playlist Item
        const currentPlayingPlaylistItem =
          Player.playlist().list()[currentPlayingPlaylistIndex];

        // Update Active Chapter File Id
        dispatch(
          setActiveChapterFileId(
            currentPlayingPlaylistItem.getInitOptions().activeChapterFileId
          )
        );
      });
    }
  }, [vPlayerInitialize]);

  useEffect(() => {
    if (vPlayer && !isChapterFileLoading && playlist.length) {
      // Add Playlist To Video Player
      vPlayer.playlist(playlist, {
        autoAdvance: 0,
        repeat: true,
        presentUpcoming: true,
      });
    }
  }, [vPlayerInitialize, playlist]);

  useEffect(() => {
    if (activeChapter && !isChapterFileLoading && vPlayer) {
      // Get Playlist Items
      const playlistItems = chapterFiles
        .filter(
          (chapterFile) =>
            chapterFile.chapterId?.["_path"]?.["segments"]?.[1] ===
              activeChapter && !chapterFile.contentType.includes("pdf")
        )
        .map((chapterFile) => ({
          publicId: chapterFile.file.cloudinaryId,
          info: { title: chapterFile.name },
          activeChapterFileId: chapterFile.id,
        }));

      // Update Playlist
      setPlaylist(playlistItems);

      // Set Default Active Chapter File Id
      dispatch(setActiveChapterFileId(playlistItems[0]?.activeChapterFileId));
    }
  }, [activeChapter, vPlayerInitialize, chapterFiles]);

  useEffect(() => {
    if (activeChapterFileId && vPlayer) {
      // Get Active Chapter File
      const activeChapterFile = chapterFiles.find(
        (chapterFile) => chapterFile.id === activeChapterFileId
      );

      // Get Active Playlist Index
      const activePlaylistIndex = playlist.findIndex(
        (item) => item.activeChapterFileId === activeChapterFileId
      );

      // Update Active Chapter File
      setActiveChapterFile(activeChapterFile);

      // Update Active Chapter File Index
      setActiveChapterFileIndex(activePlaylistIndex);
    }
  }, [activeChapterFileId, playlist, vPlayerInitialize]);

  useEffect(() => {
    if (activeChapterFileIndex > -1 && vPlayer) {
      // Set Active Playing List
      vPlayer?.playlist()?.playAtIndex(activeChapterFileIndex);
    }
  }, [activeChapterFileIndex, vPlayerInitialize]);

  return (
    <Fragment>
      <div className={`video-player ${className}`}>
        <div className="video-player__container">
          <video
            id="video-player__video"
            controls
            className="video-player__video cld-video-player cld-video-player-skin-dark cld-fluid"
          />
          <p className="video-player__name">{activeChapterFile?.name}</p>
        </div>
      </div>

      {/* Video Player Analytics */}
      <VideoAnalytics vPlayerInitialize={vPlayerInitialize} vPlayer={vPlayer} />
    </Fragment>
  );
};

VideoPlayer.defaultProps = {
  className: "",
};
