// Modules
import React from "react";
import { getFiles } from "easy-file-picker";

// Components
import { Fragment } from "react";
import { VideoPlayer, VideoPlaylistBase, Button, EmptyContent } from "../../";

// Hooks
import { useState } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import { useChapterFile, useChapter } from "../../../hooks";

// Utils
import { getStringWithoutFileExtension } from "../../../utils";

// Style
import "./video-player-base.styles.scss";

export const VideoPlayerBase = ({ className }) => {
  const [enableDragging, setEnableDragging] = useState(false);
  const [actionName, setActionName] = useState("");
  const { search } = useLocation();
  const [searchParams] = useSearchParams();
  const {
    isChapterFileLoading,
    chapterFileErrorMessage,
    chapterFiles: uploadedChapterFiles,
    dispatch,
    createChapterFiles,
    updateChapterFiles,
    deleteChapterFile,
  } = useChapterFile();
  const { activeChapter } = useChapter();
  const isEditMode = searchParams.get("edit") === "true";
  const subjectId = searchParams.get("subject");
  const isUploading = actionName === "uploading";

  /**
   * Handle Upload File For Active Chapter
   */
  const onHandleUploadChapterFile = async () => {
    // Set Action
    setActionName("uploading");

    // Get ChapterFiles
    const chapterFiles = await getFiles({
      acceptedExtensions: [".mov", ".wmv", ".avi", ".rm", ".mp4", ".pdf"],
    });

    // Get Uploaded Chapter Files Last File Position Id
    const lastUploadedChapterFilePositionId = [...uploadedChapterFiles].pop()
      ?.positionId;

    // Create Chapter Files Data
    const chapterFilesData = await Promise.all(
      chapterFiles.map(async (chapterFile, index) => ({
        file: chapterFile,
        name: getStringWithoutFileExtension(chapterFile.name),
        positionId: !lastUploadedChapterFilePositionId
          ? index + 1
          : lastUploadedChapterFilePositionId + index + 1,
        fullContentType: chapterFile.type,
        contentType: chapterFile.type.includes("video") ? "video" : "pdf",
      }))
    );

    // Create Chapter Files
    await dispatch(
      createChapterFiles(chapterFilesData, subjectId, activeChapter)
    );
  };

  /**
   * Handle Update Chapter Files
   * @param {array} chapterFiles Updated Chapter Files
   */
  const onHandleUpdateChapterFiles = (chapterFiles) => {
    // Set Action
    setActionName("uploading");

    // Update Chapter Files
    dispatch(updateChapterFiles(chapterFiles, subjectId));
  };

  /**
   * Handle Delete Chapter File
   * @param {string} chapterFileId Chapter File Id
   * @param {string} cloudinaryId Cloudinary id
   * @param {string} resourceType resource type
   */
  const onHandleDeleteChapterFile = async (
    chapterFileId,
    cloudinaryId,
    resourceType
  ) => {
    // Set Action
    setActionName("uploading");

    // Delete Chapter File
    await dispatch(
      deleteChapterFile(chapterFileId, cloudinaryId, resourceType)
    );
  };

  /**
   * Toggle Dragging Behavior
   */
  const toggleEnableDragging = () => {
    setEnableDragging((enableDragging) => !enableDragging);
  };

  return (
    <div className={`video-player-base ${className}`}>
      <div className="video-player-base__header">
        <div className="video-player-base__left">
          <h2 className="video-player-base__heading student-base-heading is-text-secondary-color">
            Course Chapter Content
          </h2>
        </div>

        <div className="video-player-base__right">
          <div className="video-player-base__btn-group">
            {isEditMode && (
              <Fragment>
                <Button
                  wrapperClassName="video-player-base__btn-wrapper"
                  className="video-player-base__btn btn--dark btn--rv1 btn--sv4"
                  onClick={toggleEnableDragging}
                >
                  {enableDragging ? "Disable" : "Enable"} Drag
                </Button>

                <Button
                  wrapperClassName="video-player-base__btn-wrapper"
                  className="video-player-base__btn btn--primary btn--rv1 btn--sv4"
                  loading={isChapterFileLoading && isUploading}
                  loadingHeight="25"
                  disabled={isChapterFileLoading && isUploading}
                  onClick={onHandleUploadChapterFile}
                >
                  Upload Files
                </Button>
              </Fragment>
            )}

            {!isEditMode && (
              <Button
                className="video-player-base__btn video-player-base__btn--cs btn--rv1"
                to={`/player/feedback${search}`}
              >
                Have Questions?
              </Button>
            )}
          </div>
        </div>
      </div>

      <EmptyContent
        className="video-player-base__empty"
        isLoading={isChapterFileLoading && !isUploading}
        errorMessage={chapterFileErrorMessage}
      >
        <div className="video-player-base__main">
          <div className="video-player-base__left">
            <VideoPlayer
              className="video-player-base__player"
              isEditMode={isEditMode}
            />
          </div>

          <div className="video-player-base__right">
            <VideoPlaylistBase
              className="video-player-basebase__playlist"
              isEditMode={isEditMode}
              enableDragging={enableDragging}
              setEnableDragging={setEnableDragging}
              onUpdateChapterFiles={onHandleUpdateChapterFiles}
              onDeleteChapterFile={onHandleDeleteChapterFile}
            />
          </div>
        </div>
      </EmptyContent>
    </div>
  );
};

VideoPlayerBase.defaultProps = {
  className: "",
};
