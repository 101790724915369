// Modules
import axios from "axios";
import fileExtension from "file-extension";

// Firebase
import {
  doc,
  addDoc,
  updateDoc,
  serverTimestamp,
  where,
  query,
  getCountFromServer,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import {
  functions,
  chapterFileCollectionRef,
  subjectCollectionRef,
  chapterCollectionRef,
} from "../../utils";

// Utils
import { AppError } from "../../utils";

/**
 * Upload Chapter Files
 * @param {array} chapterFilesData Chapter files data
 */
const uploadChapterFiles = async (chapterFilesData) => {
  try {
    // Upload Chapter Files
    const uploadResponses = await Promise.all(
      chapterFilesData.map(async (chapterFileData) => {
        // Get File Type
        const { contentType, fullContentType } = chapterFileData;

        // Create Upload Data
        const formData = new FormData();
        formData.append("file", chapterFileData.file);
        formData.append("upload_preset", "l6ozcsok");
        formData.append("folder", "finacco/chapterFiles");

        // Perform Upload
        const { data } = await axios.post(
          `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_NAME}/upload`,
          formData,
          {
            "Access-Control-Allow-Origin": "*",
          }
        );

        // Get Upload Result
        const { secure_url, duration, pages, public_id } = data;

        // Get Thumbnail
        const thumbnail = secure_url.replace(fileExtension(secure_url), "png");

        // Get Formatted Duration
        const formattedDuration = new Date()
          .clearTime()
          .addSeconds(duration)
          .toString("HH:mm:ss");

        return {
          contentType: fullContentType,
          totalDuration: pages || formattedDuration,
          file: {
            cloudinaryId: public_id,
            url: secure_url,
          },
          ...(contentType === "video" && { thumbnail }),
        };
      })
    );

    // Return Upload Responses
    return uploadResponses;
  } catch (_) {
    throw new AppError("upload-fail");
  }
};

/**
 * Get Subject Chapter Files
 * @param {string} subjectId Subject id
 */
export const getSubjectChapterFiles = async (subjectId) => {
  try {
    // Get Subject Chapter Files
    const onGetSubjectChapterFiles = httpsCallable(
      functions,
      "onGetSubjectChapterFiles"
    );
    const { data: chapterFiles } = await onGetSubjectChapterFiles(subjectId);

    // Return Subject Chapter Files
    return chapterFiles;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Create New Chapter Files
 * @param {array} chapterFilesData Chapter files data
 * @param {string} subjectId subject id
 * @param {string} chapterId chapter id
 */
export const createChapterFiles = async (
  chapterFilesData,
  subjectId,
  chapterId
) => {
  try {
    // Create Document References
    const subjectRef = doc(subjectCollectionRef, subjectId);
    const chapterRef = doc(chapterCollectionRef, chapterId);

    // Upload Chapter Files
    const uploadedChapterFiles = await uploadChapterFiles(chapterFilesData);

    // Get New Chapter Files Data
    const newChapterFilesData = uploadedChapterFiles.map(
      (uploadedChapterFile, i) => ({
        chapterId: chapterRef,
        subjectId: subjectRef,
        name: chapterFilesData[i].name,
        positionId: chapterFilesData[i].positionId,
        ...uploadedChapterFile,
        isActive: true,
        createdAt: serverTimestamp(),
      })
    );

    // Create Chapter Files
    await Promise.all(
      newChapterFilesData.map(
        async (chapterFileData) =>
          await addDoc(chapterFileCollectionRef, chapterFileData)
      )
    );

    // Get Subject Chapter Files
    const chapterFiles = await getSubjectChapterFiles(subjectId);

    // Return Subject Chapter Files
    return chapterFiles;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Update Chapter Files
 * @param {array} chapterFilesData Chapter files data
 * @param {string} subjectId Subject id
 */
export const updateChapterFiles = async (chapterFilesData, subjectId) => {
  try {
    // Update Chapter Files
    await Promise.all(
      chapterFilesData.map(async (chapterFileData) => {
        // ChapterFile Doc Reference
        const chapterFileRef = doc(
          chapterFileCollectionRef,
          chapterFileData.id
        );

        // Update Chapter File
        await updateDoc(chapterFileRef, {
          positionId: chapterFileData.positionId,
        });
      })
    );

    // Get Subject Chapter Files
    const chapterFiles = await getSubjectChapterFiles(subjectId);

    // Return Subject Chapter Files
    return chapterFiles;
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Delete A Chapter File
 * @param {array} chapterFileId Chapterfile id
 * @param {string} cloudinaryId Cloudinary id
 * @param {string} resourceType Resource type
 */
export const deleteChapterFile = async (
  chapterFileId,
  cloudinaryId,
  resourceType
) => {
  try {
    const deleteUploadedChapterFile = httpsCallable(
      functions,
      "deleteUploadedChapterFile"
    );

    // Delete Uploaded Chapter File
    await deleteUploadedChapterFile({ cloudinaryId, resourceType });

    // ChapterFile Doc Reference
    const chapterFileRef = doc(chapterFileCollectionRef, chapterFileId);

    // Delete Chapter File
    await updateDoc(chapterFileRef, { isActive: false });
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};

/**
 * Get Total Video Stat
 */
export const getTotalVideoStat = async () => {
  try {
    // Create ChapterFile Query
    const chapterFileQuery = query(
      chapterFileCollectionRef,
      where("contentType", ">=", "video"),
      where("contentType", "<=", "~")
    );

    // Get ChapterFiles Count
    const querySnapshot = await getCountFromServer(chapterFileQuery);
    const totalVideos = querySnapshot.data().count;

    // Return Total Videos
    return { totalVideos };
  } catch (err) {
    // Re-Throw Error
    throw new AppError(err);
  }
};
