// Redux
import {
  chapterFileStartLoading,
  chapterFileStopLoading,
  resetChapterFileErrorMessage,
  setChapterFiles,
  setChapterFileErrorMessage,
  delChapterFile,
  setChapterFileStat,
} from ".";
import { uiSA } from "..";

// Utils
import { getSuccess } from "../../utils";

// Apis
import * as apis from "../../apis";

// Destructure Redux Imports
const { showNotification } = uiSA;

/**
 * Get Total Video Stat
 */
export const getTotalVideoStat = () => async (dispatch) => {
  try {
    // Start Loading
    dispatch(chapterFileStartLoading());

    // Reset Error
    dispatch(resetChapterFileErrorMessage());

    // Get Video Count
    const stat = await apis.getTotalVideoStat();

    // Update ChapterFile Stat
    dispatch(setChapterFileStat(stat));
  } catch (err) {
    // Update Error Message
    dispatch(setChapterFileErrorMessage(err.message.message));
  } finally {
    // Stop Loading
    dispatch(chapterFileStopLoading());
  }
};

/**
 * Get Subject Chapter Files
 * @param {string} subjectId Subject id
 */
export const getSubjectChapterFiles = (subjectId) => async (dispatch) => {
  try {
    // Start Loading
    dispatch(chapterFileStartLoading());

    // Reset Error
    dispatch(resetChapterFileErrorMessage());

    // Get Chapter Files
    const chapterFiles = await apis.getSubjectChapterFiles(subjectId);

    // Update ChapterFiles
    dispatch(setChapterFiles(chapterFiles));
  } catch (err) {
    // Update Error Message
    dispatch(setChapterFileErrorMessage(err.message.message));
  } finally {
    // Stop Loading
    dispatch(chapterFileStopLoading());
  }
};

/**
 * Create New Chapter Files
 * @param {array} chapterFilesData Chapter files data
 * @param {string} subjectId subject id
 * @param {string} chapterId chapter id
 */
export const createChapterFiles =
  (chapterFilesData, subjectId, chapterId) => async (dispatch) => {
    try {
      // Start Loading
      dispatch(chapterFileStartLoading());

      // Reset Error
      dispatch(resetChapterFileErrorMessage());

      // Create Chapter Files
      const chapterFiles = await apis.createChapterFiles(
        chapterFilesData,
        subjectId,
        chapterId
      );

      // Update ChapterFiles
      dispatch(setChapterFiles(chapterFiles));

      // Show Success Notification On Creating Chapter Files
      dispatch(showNotification(getSuccess("created")));
    } catch (err) {
      // Show Error In Notification
      dispatch(showNotification(err.message));
    } finally {
      // Stop Loading
      dispatch(chapterFileStopLoading());
    }
  };

/**
 * Update Chapter Files
 * @param {array} chapterFilesData Chapter files data
 * @param {string} subjectId subject id
 */
export const updateChapterFiles =
  (chapterFilesData, subjectId) => async (dispatch) => {
    try {
      // Start Loading
      dispatch(chapterFileStartLoading());

      // Update Chapter Files
      const chapterFiles = await apis.updateChapterFiles(
        chapterFilesData,
        subjectId
      );

      // Update ChapterFiles
      dispatch(setChapterFiles(chapterFiles));

      // Show Success Notification On Updating Chapter Files
      dispatch(showNotification(getSuccess("updated")));
    } catch (err) {
      // Show Error In Notification
      dispatch(showNotification(err.message));
    } finally {
      // Stop Loading
      dispatch(chapterFileStopLoading());
    }
  };

/**
 * Delete A Chapter File
 * @param {array} chapterFileId Chapterfile id
 * @param {string} cloudinaryId Cloudinary id
 * @param {string} resourceType Resource type
 */
export const deleteChapterFile =
  (chapterFileId, cloudinaryId, resourceType) => async (dispatch) => {
    try {
      // Start Loading
      dispatch(chapterFileStartLoading());

      // Delete Chapter File
      await apis.deleteChapterFile(chapterFileId, cloudinaryId, resourceType);

      // Delete ChapterFile In Redux Store
      dispatch(delChapterFile(chapterFileId));

      // Show Success Notification On Deleting Chapter File
      dispatch(showNotification(getSuccess("deleted")));
    } catch (err) {
      // Show Error In Notification
      dispatch(showNotification(err.message));
    } finally {
      // Stop Loading
      dispatch(chapterFileStopLoading());
    }
  };
